import {
    deleteObject,
    getDownloadURL,
    getStorage,
    ref as storageRef,
    ref,
    uploadBytes,
    uploadBytesResumable,
} from 'firebase/storage'
import { storage } from '../../../firebase_config/firebase_config'
import { nanoid } from 'nanoid'

export const getVideo = async (path) => {
    try {
        if (!path) {
            throw new Error('no video path')
        } else if (['videos/', 'tutorials/'].find(v => path?.includes(v))) {
            const storageRef = ref(storage, path)
            const url = await getDownloadURL(storageRef)
            return url
        } else {
            const url = 'https://storage.googleapis.com/output-bucket-test-viscap/' + path
            return url
        }
    } catch (error) {
        console.log(error)
    }
}

export const getImage = async (path) => {
    if (!path) return ''
    const storageRef = ref(storage, path)
    try {
        const url = await getDownloadURL(storageRef)
        return url
    } catch (error) {
        console.log(error)
    }
}

/*
export const uploadClipVideo = async (file, path, setProgress, onCompleteCb) => {
    const videoRef = ref(storage, path)

    try {
        const uploadTask = uploadBytesResumable(videoRef, file)
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            },
            (error) => {
                console.log(error)
                throw new Error('File upload unsuccessful.')
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    onCompleteCb(downloadURL)
                })
            }
        )
    } catch (err) {
        console.log(err)
    }
}
*/

export const uploadElementVideo = async (file: File, path: string, setProgress, onCompleteCb) => {
    const videoRef = ref(storage, path)

    try {
        const uploadTask = uploadBytesResumable(videoRef, file)
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            },
            (error) => {
                console.log(error)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    onCompleteCb(downloadURL, uploadTask.snapshot.ref.fullPath)
                })
            }
        )
        return uploadTask
    } catch (err) {
        console.log(err)
    }
}

export const removeElementVideoByBucketPath = async (bucketPath) => {
    console.log('removeElementVideoByBucketPath', bucketPath)
    try {
        const storageRef = ref(storage, bucketPath)
        await deleteObject(storageRef)
    } catch (error) {
        console.log(error)
    }
}

export const removeElementVideo = async (url) => {
    const path = decodeURIComponent(url.split('?')[0].split('/o/')[1])

    console.log('removeElementVideo', path)

    try {
        const storageRef = ref(storage, path)
        await deleteObject(storageRef)
    } catch (error) {
        console.log(error)
    }
}

export const uploadTeamLogo = async (teamId, file, onCompleteCb) => {
    const fileName = nanoid() + file.name
    const storageRef = ref(storage, `team/${teamId}/${fileName}`)

    try {
        await uploadBytes(storageRef, file)
        const url = await getDownloadURL(storageRef)
        onCompleteCb(url)
    } catch (err) {
        console.log(err)
        throw new Error('File upload unsuccessful.')
    }
}

export const uploadMediaFile = async ({
    storagePath,
    file,
    fileType,
}: {
    storagePath: string
    file: File
    fileType: 'photo' | 'video'
}): Promise<string> => {
    const storageRef = ref(storage, storagePath)

    try {
        if (fileType === 'photo') {
            await uploadBytes(storageRef, file)

            return await getDownloadURL(storageRef)
        } else {
            const uploadTask = uploadBytesResumable(storageRef, file)

            uploadTask.on(
                'state_changed',
                () => {},
                (error) => {
                    console.log(error)
                    throw new Error('File upload unsuccessful.')
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        return downloadURL
                    })
                }
            )
            // Wait for upload to complete

            await uploadTask

            return await getDownloadURL(storageRef)
        }
    } catch (err) {
        console.log(err)
        throw new Error('File upload unsuccessful.')
    }
}

export const uploadFileFromUrl = async (fileUrl: string, destinationPath: string) => {
    try {
        const response = await fetch(fileUrl)
        if (!response.ok) {
            throw new Error(`Failed to download file: ${response.status}`)
        }

        const blob = await response.blob()

        const storage = getStorage()
        const fileRef = storageRef(storage, destinationPath)

        await uploadBytes(fileRef, blob, {
            contentType: response.headers.get('content-type') || 'application/octet-stream',
        })

        return await getDownloadURL(fileRef)
    } catch (err) {
        console.log('uploadFromUrl err')
        console.log(err)
        throw new Error(`Upload failed: ${err.message}`)
    }
}