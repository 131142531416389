import { AuditionFormValues, TalentData } from './types'
import { capitalize, trim } from 'lodash'
import {
    locationOptions,
    preferredContactMethods,
    remoteWorkStatusOptions,
    smartphones,
    socialMedia,
    socialMediaDomains,
} from '../../../../data/options'

export const AUDITION_FORM_WIDTH = 680

export const initAuditionValues: AuditionFormValues = {
    info: {
        firstName: '',
        lastName: '',
        email: '',
        dateOfBirth: '',
        location: [],
        phoneNumber: '',
        portfolioLink: '',
        preferredContactMethod: preferredContactMethods[0],
        remoteWorkStatus: remoteWorkStatusOptions[0].value,
        socialMediaProfiles: [
            {
                name: socialMedia[0],
                url: '',
            },
        ],
    },
    photos: {
        headshot: null,
        extra: [],
    },
    equipmentAccess: {
        ringLight: false,
        tripod: false,
        audioEquipment: false,
        smartphone: false,
        smartphoneBrand: '',
        smartphoneModel: '',
    },
    appearance: {
        eyeColor: '',
        hairColor: '',
        hairLength: '',
        hairType: '',
        gender: '',
        ethnicity: '',
    },
    measurements: {
        height: {
            feet: '',
            inch: '',
        },
        weight: '',
        shoeSize: '',
        pantsSize: '',
        shirtSize: '',
        dressSize: '',
        underwearSize: '',
        bust: '',
        waist: '',
        hips: '',
        chest: '',
        inseam: '',
        shoulderToShoulder: '',
        shoulderToWrists: '',
        neckToThumb: '',
    },
    vocalInfo: {
        primaryLanguage: 'English',
        additionalLanguages: [],
        accents: [],
    },
    screenTest: null,
    miscInfo: {
        industryPreferences: ['Open to all'],
        unionAffiliation: false,
        unionName: '',
        nonCompeteAgreement: false,
        nonCompeteAgreementDetails: '',
        rentalLocation: false,
        rentalLocationPhotos: [],
        extraVideos: [],
    },
    terms: false,
}

export const fieldsToTrim = [
    'info.firstName',
    'info.lastName',
    'miscInfo.unionName',
    'miscInfo.nonCompeteAgreementDetails',
]

export const getSocialMediaProfiles = (talentSM: string[]) => {
    return talentSM.map((value) => {
        const targetEntry = Object.entries(socialMediaDomains).find(([sm, domains]) => {
            return !!domains.find((d) => value.includes(d))
        })

        return {
            name: targetEntry ? targetEntry[0] : 'other',
            url: value,
        }
    })
}

export const getSmartphoneBrand = (talentSmartphoneModel: string) => {
    if (!talentSmartphoneModel) return ''

    let targetBrand = smartphones.find((item) => item.name === talentSmartphoneModel.split(' ')[0].toLowerCase())

    if (targetBrand) {
        return targetBrand.name
    } else if (!targetBrand && talentSmartphoneModel.toLowerCase().includes('iphone')) {
        return 'apple'
    } else {
        return 'other'
    }
}

export const getSmartphoneModel = (talentSmartphoneModel: string) => {
    let targetBrand = smartphones.find((item) => item.name === getSmartphoneBrand(talentSmartphoneModel))

    if (!targetBrand) return ''

    return targetBrand.models.find((m) => talentSmartphoneModel.toLowerCase().includes(m.toLowerCase())) || 'Other'
}

export const parseTalentSmartphoneModel = (talentSmartphoneBrand: string, talentSmartphoneModel: string) => {
    if (talentSmartphoneBrand === 'other') {
        return talentSmartphoneModel
    } else if (talentSmartphoneBrand && talentSmartphoneModel === 'Other') {
        return capitalize(talentSmartphoneBrand)
    } else {
        return `${capitalize(talentSmartphoneBrand)} ${talentSmartphoneModel}`
    }
}

export const getLocation = (talentLocation: string) => {
    if (!talentLocation) return []

    const locationParts = talentLocation.split(',').map((v) => trim(v))

    return locationParts.map(l => ({
        label: l,
        value: '',
    }))
}

export const parseTalentLocation = (location: AuditionFormValues['info']['location']) => {
    return location.map((item) => item.label).join(', ')
}

export const getHeight = (talentHeight: string) => {
    if (!talentHeight || !talentHeight.includes("'")) {
        return {
            feet: '',
            inch: '',
        }
    }

    const heightFeet = talentHeight.split("\'")[0]
    const heightInch = talentHeight.split("\'")[1].split('\"')[0]

    return {
        feet: heightFeet,
        inch: heightInch && heightInch !== '0' ? heightInch : '',
    }
}

export const getTalentFormName = (name: string) => {
    const formattedName = name?.trim().replace(/\s+/, ' ')

    return {
        firstName: formattedName?.split(' ')[0] || initAuditionValues.info.firstName,
        lastName: formattedName?.split(' ')[1] || initAuditionValues.info.firstName,
    }
}

export const getInitAuditionValuesNew = (email: string, name?: string): AuditionFormValues => {
    const nameFields = getTalentFormName(name)

    return {
        ...initAuditionValues,
        info: {
            ...initAuditionValues.info,
            email: email,
            firstName: nameFields.firstName || initAuditionValues.info.firstName,
            lastName: nameFields.lastName || initAuditionValues.info.firstName,
        },
    }
}

export const getInitAuditionValuesExisting = (talent: TalentData): AuditionFormValues => {
    const nameFields = getTalentFormName(talent.name)

    return {
        info: {
            firstName: nameFields.firstName || initAuditionValues.info.firstName,
            lastName: nameFields.lastName || initAuditionValues.info.firstName,
            email: talent.email,
            dateOfBirth: talent?.info.dateOfBirth || initAuditionValues.info.dateOfBirth,
            location: getLocation(talent?.info?.location) || initAuditionValues.info.location,
            phoneNumber: String(talent?.info.phoneNumber) || initAuditionValues.info.phoneNumber,
            portfolioLink: talent?.info.portfolioLink || initAuditionValues.info.portfolioLink,
            preferredContactMethod:
                talent?.info?.preferredContactMethod || initAuditionValues.info.preferredContactMethod,
            remoteWorkStatus: talent?.info?.remoteWorkStatus || initAuditionValues.info.remoteWorkStatus,
            socialMediaProfiles: talent?.info?.socialMediaProfiles
                ? getSocialMediaProfiles(talent?.info?.socialMediaProfiles)
                : initAuditionValues.info.socialMediaProfiles,
        },
        photos: {
            headshot: talent.headshot || initAuditionValues.photos.headshot,
            extra: talent.media?.talentPhotos || initAuditionValues.photos.extra,
        },
        equipmentAccess: {
            ringLight: talent?.equipment?.ringLight || initAuditionValues.equipmentAccess.ringLight,
            tripod: talent?.equipment?.tripod || initAuditionValues.equipmentAccess.tripod,
            audioEquipment: talent?.equipment?.audioEquipment || initAuditionValues.equipmentAccess.audioEquipment,
            smartphone: talent?.equipment?.smartphone || initAuditionValues.equipmentAccess.smartphone,
            smartphoneBrand:
                getSmartphoneBrand(talent?.equipment?.smartphoneModel) ||
                initAuditionValues.equipmentAccess.smartphoneBrand,
            smartphoneModel:
                getSmartphoneModel(talent?.equipment?.smartphoneModel) ||
                initAuditionValues.equipmentAccess.smartphoneModel,
        },
        appearance: {
            eyeColor: talent?.appearance?.eyeColor || initAuditionValues.appearance.eyeColor,
            hairColor: talent?.appearance?.hairColor || initAuditionValues.appearance.hairColor,
            hairLength: talent?.appearance?.hairLength || initAuditionValues.appearance.hairLength,
            hairType: talent?.appearance?.hairType || initAuditionValues.appearance.hairType,
            gender: talent?.appearance?.gender || initAuditionValues.appearance.gender,
            ethnicity: talent?.appearance?.ethnicity || initAuditionValues.appearance.ethnicity,
        },
        measurements: {
            height: getHeight(talent?.measurements?.height),
            weight: talent?.measurements?.weight || initAuditionValues.measurements.weight,
            shoeSize: talent?.measurements?.shoeSize || initAuditionValues.measurements.shoeSize,
            pantsSize: talent?.measurements?.pantsSize || initAuditionValues.measurements.pantsSize,
            shirtSize: talent?.measurements?.shirtSize || initAuditionValues.measurements.shirtSize,
            dressSize: talent?.measurements?.dressSize || initAuditionValues.measurements.dressSize,
            underwearSize: talent?.measurements?.underwearSize || initAuditionValues.measurements.underwearSize,
            bust: talent?.measurements?.bust || initAuditionValues.measurements.bust,
            waist: talent?.measurements?.waist || initAuditionValues.measurements.waist,
            hips: talent?.measurements?.hips || initAuditionValues.measurements.hips,
            chest: talent?.measurements?.chest || initAuditionValues.measurements.chest,
            inseam: talent?.measurements?.inseam || initAuditionValues.measurements.inseam,
            shoulderToShoulder:
                talent?.measurements?.shoulderToShoulder || initAuditionValues.measurements.shoulderToShoulder,
            shoulderToWrists:
                talent?.measurements?.shoulderToWrists || initAuditionValues.measurements.shoulderToWrists,
            neckToThumb: talent?.measurements?.neckToThumb || initAuditionValues.measurements.neckToThumb,
        },
        vocalInfo: {
            primaryLanguage: talent?.vocalInfo?.primaryLanguage || initAuditionValues.vocalInfo.primaryLanguage,
            additionalLanguages:
                talent?.vocalInfo?.additionalLanguages || initAuditionValues.vocalInfo.additionalLanguages,
            accents: talent?.vocalInfo?.accents || initAuditionValues.vocalInfo.accents,
        },
        screenTest: talent?.media?.screenTest || initAuditionValues.screenTest,
        miscInfo: {
            industryPreferences:
                talent?.miscInfo?.industryPreferences || initAuditionValues.miscInfo.industryPreferences,
            unionAffiliation: talent?.miscInfo?.unionAffiliation || initAuditionValues.miscInfo.unionAffiliation,
            unionName: talent?.miscInfo?.unionName || initAuditionValues.miscInfo.unionName,
            nonCompeteAgreement:
                talent?.miscInfo?.nonCompeteAgreement || initAuditionValues.miscInfo.nonCompeteAgreement,
            nonCompeteAgreementDetails:
                talent?.miscInfo?.nonCompeteAgreementDetails || initAuditionValues.miscInfo.nonCompeteAgreementDetails,
            rentalLocation: talent?.miscInfo?.rentalLocation || initAuditionValues.miscInfo.rentalLocation,
            rentalLocationPhotos:
                talent?.miscInfo?.rentalLocationPhotos || initAuditionValues.miscInfo.rentalLocationPhotos,
            extraVideos: talent?.media?.additionalVideos || initAuditionValues.miscInfo.extraVideos,
        },
        terms: undefined,
    }
}
