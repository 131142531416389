import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { BASE_URL } from '../../firebase_config/firebase_config'

let authToken: string | null = null

export const setAuthToken = (token: string | null) => {
    authToken = token
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${BASE_URL}/api`,
})

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`
        }
        return config
    },
    (error) => {
        console.log('Error in request interceptor:', error)
        Promise.reject(error)
    }
)

export default axiosInstance
