import { createContext, ReactNode, useContext, useState, FC, useEffect } from 'react'
import {
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    getAuth,
    User,
    UserCredential,
    signInWithCustomToken,
    updateProfile,
} from 'firebase/auth'
import { auth, functions } from '../../../firebase_config/firebase_config'
import { useRouter } from 'next/router'
import { getUser, updateUser } from '../../../api/firestore/users'
import { UserData } from '../../../types'
import { getClientSubscriptions } from '../../../api/firestore/clients'
import LogRocket from 'logrocket'
import { httpsCallable } from 'firebase/functions'
import { DocumentData, useDoc } from '@tatsuokaniwa/swr-firestore'
import { setAuthToken } from '../../../api/axios'

interface IAuthData {
    currentUser: User | null
    userDB: DocumentData<UserData> | null
    claims: any
    loadingAuth: boolean
    userCredFromSlack: UserCredential
    slackLoginRedirect: boolean
    slackLoginRedirectDone: () => void
    handleSignIn: (email: string, password: string) => Promise<void>
    handleSignInWithCustomToken: (token: string) => Promise<void>
    handleSignOut: () => Promise<void>
    loginWithSlack: () => Promise<UserCredential>
    handleUpdatePassword: (newPassword: string) => Promise<void>
    handleUpdateProfile: ({ displayName, photoURL }: { displayName?: string; photoURL?: string }) => Promise<void>
    fromViscap: boolean
    isManualLogout: boolean
}

export const AuthContext = createContext<IAuthData>(null!)

export const useAuth = () => {
    return useContext(AuthContext)
}

interface VerificationResult {
    success: string
    key: string
    companyName: string
    clientId: string
}

interface AddRoleResult {
    success: string
}

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const [loadingAuth, setLoadingAuth] = useState<boolean>(true)
    const [userRole, setUserRole] = useState<string>('')
    const [userCredFromSlack, setUserCredFromSlack] = useState<UserCredential>()
    const [slackLoginRedirect, setSlackLoginRedirect] = useState(false)
    const [fromViscap, setFromViscap] = useState(false)
    // const [userDB, setUserDB] = useState<UserData>(null)
    const [claims, setClaims] = useState({})
    const [manualLogout, setManualLogout] = useState(false)

    const { data: userDB = null } = useDoc<UserData>(
        currentUser
            ? {
                  path: `users/${currentUser.uid}`,
                  parseDates: ['removeOn'],
              }
            : null
    )

    const router = useRouter()

    const handleSignInWithCustomToken = async (token: string) => {
        try {
            await signInWithCustomToken(auth, token)
        } catch (err) {
            console.log(err)
            throw new Error('There was an error attempting to sign you in')
        }
    }

    const handleSignIn = async (email: string, password: string) => {
        setLoadingAuth(true)
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (err) {
            if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
                throw new Error('There was an error attempting to sign you in: Wrong email or password.')
            }
            throw new Error('There was an error attempting to sign you in')
        }
    }

    const handleUpdatePassword = async (newPassword: string) => {
        try {
            const updatePassword = httpsCallable(functions, 'changePassword')
            await updatePassword({ uid: currentUser.uid, newPassword: newPassword })
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleSignOut = async () => {
        try {
            console.log('sign out')
            await signOut(auth)
            setCurrentUser(null)
            setAuthToken(null)
            setUserCredFromSlack(null)
            setManualLogout(true)
            setLoadingAuth(false)
            sessionStorage.setItem('currentUserEmail', null)
            sessionStorage.setItem('currentUserId', null)
        } catch (err) {
            console.log(err)
            throw new Error('There was an issue signing you out')
        }
    }

    const loginWithSlack = async () => {
        const provider = new OAuthProvider('oidc.slack')

        provider.addScope('openid')
        provider.addScope('email')
        provider.addScope('profile')

        const credential = await signInWithPopup(auth, provider)
        setUserCredFromSlack(credential)

        return credential
    }

    const handleUpdateProfile = async (params) => {
        try {
            await updateProfile(currentUser, { ...params })
        } catch (error) {
            throw new Error(error)
        }
    }

    useEffect(() => {
        const unsubAuthState = auth.onAuthStateChanged(async (user) => {
            setLoadingAuth(true)

            const setNoneUser = async () => {
                console.log('no user!')
                setCurrentUser(null)
                setAuthToken(null)
                // setUserDB(null)
                setUserRole('')
                if (router.pathname.startsWith('/admin')) {
                    router.push('/login')
                }
            }

            if (auth.currentUser?.isAnonymous) {
                console.log('anonymous user')
                return
            }

            if (user) {
                try {
                    const userDBData: any = await getUser(user.uid)
                    if (userDBData) {
                        if (!('email' in userDBData)) {
                            await setNoneUser()
                            setSlackLoginRedirect(true)
                            return
                        }

                        const fromViscap_ = userDBData.email.endsWith('@viscapmedia.com')
                        // const fromNullSquad = userDBData.email.endsWith('@null-squad.com')
                        setFromViscap(fromViscap_)

                        // if (process.env.GIT_BRANCH === 'develop') {
                        //     if (!fromViscap_ && !fromNullSquad) {
                        //         throw new Error('Company email is required to access preview version')
                        //     }
                        // }

                        setCurrentUser(user)

                        const idTokenResult = await auth.currentUser.getIdTokenResult(true)
                        const claims = idTokenResult.claims

                        setClaims(claims)
                        // setUserDB(userDBData)
                        setUserRole(userDBData.role)
                        sessionStorage.setItem('currentUserEmail', userDBData.email)
                        sessionStorage.setItem('currentUserId', userDBData.uid)
                        LogRocket.identify(userDBData.uid, { email: userDBData.email })
                        console.log('notice: user is logged in')
                    }
                } catch (err) {
                    console.log(err)
                    // throw new Error('Could not retrieve your information from our records')
                    if (router.pathname.includes('admin')) {
                        await router.push('/login')
                    }
                }
            } else {
                await setNoneUser()
            }

            setLoadingAuth(false)
        })

        const unsubTokenChanged = auth.onIdTokenChanged(async (user) => {
            if (user) {
                try {
                    const idToken = await user.getIdToken()
                    setAuthToken(idToken)
                } catch (error) {
                    console.error('Error refreshing token:', error)
                }
            } else {
                setAuthToken(null)
            }
        })

        return () => {
            unsubAuthState()
            unsubTokenChanged()
        }
    }, [router])

    const slackLoginRedirectDone = () => setSlackLoginRedirect(false)

    const value = {
        currentUser,
        userDB,
        claims,
        loadingAuth,
        userCredFromSlack,
        slackLoginRedirect,
        slackLoginRedirectDone,
        handleSignIn,
        handleSignInWithCustomToken,
        handleSignOut,
        loginWithSlack,
        handleUpdatePassword,
        handleUpdateProfile,
        fromViscap,
        isManualLogout: manualLogout,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
