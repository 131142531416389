import { useEffect } from 'react'
import PropTypes from 'prop-types'

declare global {
    interface Window {
        zE?: any
        zESettings?: any
    }
}

const canUseDOM = () => {
    return typeof window !== 'undefined' && !!window.document && !!window.document.createElement
}

export const ZendeskAPI = (...args) => {
    if (canUseDOM() && window.zE) {
        window.zE.apply(null, args)
    }
}

const Zendesk = ({ zendeskKey, defer, onLoaded, ...args }) => {
    useEffect(() => {
        const insertScript = () => {
            const script = document.createElement('script')
            if (defer) {
                script.defer = true
            } else {
                script.async = true
            }

            script.id = 'ze-snippet'
            script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`

            script.addEventListener('load', () => {
                if (typeof onLoaded === 'function') {
                    console.log('Zendesk onLoaded')
                    onLoaded()
                }
            })

            document.body.appendChild(script)
        }

        if (canUseDOM() && !window.zE) {
            insertScript()
            window.zESettings = args
        }
    }, [zendeskKey, defer, onLoaded, args])

    return null
}

Zendesk.propTypes = {
    zendeskKey: PropTypes.string.isRequired,
    defer: PropTypes.bool,
    onLoaded: PropTypes.func,
}

export default Zendesk
